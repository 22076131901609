@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap');

@import "./theme.sass";
@import "bulma/sass/utilities/_all.sass";
@import "bulma/sass/base/_all.sass";
@import "bulma/sass/layout/section.sass";
@import "bulma/sass/elements/container.sass";
@import "bulma/sass/elements/content.sass";
@import "bulma/sass/grid/columns.sass";
@import "bulma/sass/components/level.sass";

.section-content {
	padding-top: 0rem !important;
	padding-bottom: 0rem !important;
	font-size: 1.1rem;
	letter-spacing: -0.38px; }

.section-navbar {
	background: $background-navbar;
	padding-top: 0 !important;
	padding-bottom: 0 !important; }
