@import "../theme.sass";

.Publications {
	margin-top: 2rem !important;
	.section-title {
		margin-top: 2rem;
		margin-bottom: 1rem;
		font-weight: bolder;
		font-size: 1.25rem; }
	.publication {
		//padding-left: 0.5rem
		margin-bottom: 1.5rem; }
	.venue {
		font-size: 0.95rem; }
	.name {
		font-weight: bold; }
	.authors {
		font-size: 0.95rem;
		.me {
			border-bottom: 1px solid #666; } }
	.link {
		text-decoration: none;
		border: none; }
	.brief {
		margin-top: .25rem;
		max-width: 800px;
		font-size: 0.95rem;
		display: none; } }
