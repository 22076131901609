@import "bulma/sass/utilities/initial-variables";
@import "./resources/fonts/sf/_all.sass";

$color-tangerine-tango: rgb(226,73,47);
$color-vincross-purple: #492669;
$color-apple-black: #333;

$family-sans-serif: "SF Pro Text", sans-serif;
$family-serif: serif;
$font-roboto: "Roboto", sans-serif;
$text: $color-apple-black;
$text-link: $color-tangerine-tango;
$background-navbar: white;

@import "bulma/sass/utilities/_all";

@mixin sans-font {
	font-family: $family-sans-serif; }

@mixin serif-font {
	font-family: $family-serif; }

@mixin font-roboto {
	font-family: $font-roboto; }

@include widescreen {
	.container {
		max-width: 1020px !important; } }

body {
	font-weight: 400 !important; }

a {
	text-decoration: none;
	color: $text; }

.link {
	position: relative;
	color: $text;
	border-bottom: 1px dotted $text;
	&.color-vincross-purple {
		border-bottom-color: $color-vincross-purple;
		span::before {
			border-bottom-color: $color-vincross-purple; } }
	&.color-tangerine-tango {
		border-bottom-color: $color-tangerine-tango;
		span::before {
			border-bottom-color: $color-tangerine-tango; } }
	@include mobile {
		border-bottom: 2px dotted $text; }
	@include desktop {
		span {
			position: relative;
			display: inline-block;
			&::before {
				border-bottom: 2px solid $text;
				clip-path: polygon(0 0, 0 0, 0% 100%, 0 100%);
				transition: clip-path 200ms ease;
				position: absolute;
				top: -.25rem;
				content: attr(data-content);
				color: rgba(0,0,0,0); } }
		&:hover span::before {
			clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%); } } }


