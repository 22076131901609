@import "../theme.sass";

.Navbar {
	@include font-roboto;
	margin-top: 1.6rem;
	height: 6rem;
	.container {
		height: 100%; }
	.row {
		display: flex;
		align-items: center;
		height: 100%; }
	.item {
		padding-left: 1.5rem;
		padding-right: 1.5rem;
		@include mobile {
			padding-left: .75rem;
			padding-right: .75rem; }
		&:first-child {
			padding-left: 0rem; }
		font-size: 1.337rem;
		font-weight: bold;
		@include mobile {
			font-size: 1.15rem; }
		cursor: default;
		a, span {
			color: $text-light;
			height: 100%;
			display: flex;
			align-items: center;
			&:hover, &.active {
				color: $text;
				border: none; } } } }
