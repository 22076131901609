@import "../theme.sass";

.Bio {
	padding-bottom: 2rem;
	border-bottom: 1px solid #ddd;
	.columns {
		align-items: center;
		flex-direction: row-reverse; }
	.short-bio {
		cursor: default;
		@include desktop {
			padding-right: 0.95rem; } }
	.portrait {
		text-align: center;
		@include mobile {
			margin-top: .6rem;
			margin-bottom: 1rem; }
		@include tablet {
			max-width: 19rem;
			padding-left: 2rem; }
		img {
			width: 100%; }
		.caption {
			font-size: 0.875rem;
			color: $text-light;
			margin-top: 0.25rem; } } }
