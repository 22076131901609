@import "../theme.sass";
.Footer {
	.Navbar {
		height: 6rem;
		@include mobile {
			height: 4rem;
			.row {
				justify-content: center; } }
		.item a, span {
			@include sans-font;
			font-size: 0.875rem;
			font-weight: normal;
			color: #444; } }
	.wechat-qr {
		canvas {
			height: 8rem;
			padding: 0.5rem; } }
	.wechat-id {
		font-weight: 500;
		text-align: center; }
	#wechat {
		padding: 0.5rem; } }
